@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .h-screen {
    height: 100dvh;
  }

  .min-h-screen {
    min-height: 100dvh;
  }
}
